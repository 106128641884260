import ChatMessage from "./chat-message";
import { useState, useRef, useEffect } from "react";
import QuestionInputBox from "./question-input-box";
import tzuchiImg from "../images/Tzu_Chi_Logo.png";

import './chat-window.css';
import { Message, QNA, QuestionHistory, QuestionHistoryContainerItem, ResponseSource } from "../constants/types";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import { BASE_BACKGROUND_COLOUR } from "../constants/colours";
import ViewSourcesContent from "./view-sources-content";
import { useHistoryProvider } from "../providers/history-provider";
import { useSourcesPanelProvider } from "../providers/sources-panel-provider";
import { useChatWindowProvider } from "../providers/chat-window-provider";

const ChatWindow = ({
  handleSubmit,
  handleKeyPress,
  createNewSession,
}: {
  handleSubmit: () => void,
  handleKeyPress: (event: { keyCode: number; which: number; }) => void,
  createNewSession: () => void,
}) => {
  const { t } = useTranslation();
  const { history, selectedHistory, currentLoadingHistory } = useHistoryProvider();
  const { handleToggleViewSourcesPanel, setViewSourcesPanelContent } = useSourcesPanelProvider();
  const {
    input,
    setInput,
    isLoading,
    currentHistory,
    setCurrentHistory,
    isQuestionBoxDisabled,
    setIsQuestionBoxDisabled,
  } = useChatWindowProvider();

  const toggleViewSourcesPanel = (responseSources: ResponseSource[]) => {
    setViewSourcesPanelContent(<ViewSourcesContent responseSources={responseSources} selectedHistory={selectedHistory} />);
    handleToggleViewSourcesPanel();
  }

  useEffect(() => {
    const currentSelectedHistory = history.find((historyPiece) => historyPiece.sessionId === selectedHistory);
    if (currentSelectedHistory) {
      setCurrentHistory(currentSelectedHistory.history);
    }
    if (isLoading || (currentSelectedHistory && currentSelectedHistory.history.length > 0)) {
      setIsQuestionBoxDisabled(true);
    } else {
      setIsQuestionBoxDisabled(false);
    }
  }, [history, selectedHistory])

  const showInputField = () => {
    if (isQuestionBoxDisabled && isLoading) {
      return <></>;
    } else if (!isQuestionBoxDisabled) {
      return (
        <QuestionInputBox
          value={input}
          isLoading={isLoading}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setInput(e.currentTarget.value)}
          onClick={handleSubmit} />
      );
    } else {
      return (
        <Box sx={anotherQuestionButtonContainerStyle}>
          <Box sx={anotherQuestionButtonStyle} onClick={createNewSession}>
            {t("chat_window.ask_another_question")}
          </Box>
        </Box>
      );
    }
  }

  return (
    <div style={windowStyle} onKeyPress={handleKeyPress}>
      <div className="content-container" style={contentStyle}>
        {currentHistory.length === 0 &&
          <div className="chatHistoryEmptyText">
            <img src={tzuchiImg} className="ragdollImg" alt="ragdoll cat img" />
            {t("chat_window.ask_me_a_question")}
          </div>
        }
        {currentHistory.map((sessionHistoryItem: QuestionHistory, i) =>
          <ChatMessage
            key={selectedHistory + `-${i}`}
            message={sessionHistoryItem.message}
            qna={currentHistory[i].qna}
            responseSource={currentHistory[i].responseSources}
            toggleViewSourcesPanel={toggleViewSourcesPanel} />
        )}
        {isLoading && currentLoadingHistory === selectedHistory &&
          <div className="isLoadingContainer">
            <CircularProgress sx={loadingIconStyle} size={30} />
            {t("chat_window.loading")}
          </div>
        }
      </div>
      {showInputField()}
    </div>
  );
}

const windowStyle = {
  width: '100%',
  fontSize: 20,
}

const contentStyle = {
  overflow: "auto",
}

const loadingIconStyle = {
  marginRight: '15px',
  color: 'white'
}

const anotherQuestionButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const anotherQuestionButtonStyle = {
  cursor: 'pointer',
  fontSize: '18px',
  border: '1px solid white',
  borderRadius: 2,
  padding: '10px 15px 10px 15px',
  width: 'auto',
  transition: '0.3s',
  ':hover': {
    bgcolor: 'white',
    fontSize: '18px',
    color: BASE_BACKGROUND_COLOUR,
    padding: '10px 18px 10px 18px',
  },
}

export default ChatWindow;