import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { QuestionHistoryContainerItem } from "../constants/types";
import { getHistoryFromLocalStorage, saveHistoryToLocalStorage } from "../helpers/local-storage-helpers";
import { generateId } from "../helpers/generate-id";

type HistoryProviderProps = {
  children?: any
}

interface HistoryContextData {
  history: QuestionHistoryContainerItem[],
  setHistory: Dispatch<SetStateAction<QuestionHistoryContainerItem[]>>,
  selectedHistory: string,
  setSelectedHistory: Dispatch<SetStateAction<string>>,
  createNewSession: () => void,
  currentLoadingHistory: string,
  setCurrentLoadingHistory: Dispatch<SetStateAction<string>>,
  canCreateNewSession: () => boolean
}

const HistoryContext = createContext<HistoryContextData>({
  history: [],
  setHistory: () => { },
  selectedHistory: '',
  setSelectedHistory: () => { },
  createNewSession: () => { },
  currentLoadingHistory: '',
  setCurrentLoadingHistory: () => { },
  canCreateNewSession: () => true
});

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }: { children?: any }) => {
  const localHistory = getHistoryFromLocalStorage();
  const [history, setHistory] = useState<QuestionHistoryContainerItem[]>(localHistory);
  const [selectedHistory, setSelectedHistory] = useState<string>(localHistory[0].sessionId);
  const [currentLoadingHistory, setCurrentLoadingHistory] = useState<string>('');

  useEffect(() => {
    saveHistoryToLocalStorage(history);
    const existingIndex = history.findIndex((historyItem) => historyItem.sessionId === selectedHistory)
    //Low Prio: Fix this, selectedHistory gets swapped to the one youre deleting when you delete it probably because on click it updates selectedHistory
    if (existingIndex != -1) {
      setSelectedHistory(history[existingIndex].sessionId);
    } else {
      setSelectedHistory(history[0].sessionId);
    }
  }, [history]);

  const createNewSession = () => {
    if (canCreateNewSession()) {
      const newSessionId = generateId();
      setHistory((prevHistory) => [{ sessionId: newSessionId, history: [] }, ...prevHistory]);
      setSelectedHistory(newSessionId);
    } else {
      const emptySessions = history.filter((historyItem) => historyItem.history.length === 0)
      setSelectedHistory(emptySessions[0].sessionId);
    }

  }

  const canCreateNewSession = () => {
    return history.filter((historyItem) => historyItem.history.length === 0).length === 0;
  }

  return (
    <HistoryContext.Provider
      value={{
        history,
        setHistory,
        selectedHistory,
        setSelectedHistory,
        createNewSession,
        currentLoadingHistory,
        setCurrentLoadingHistory,
        canCreateNewSession
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export function useHistoryProvider() {
  return useContext(HistoryContext);
}