import { LIGHT_GRAY_BORDER_COLOUR, LIGHT_GRAY_TEXT_COLOUR } from '../constants/colours';
import { QuestionHistoryContainerItem } from '../constants/types';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

function HistoryLine({
  index,
  isLoading,
  selectedHistory,
  history,
  historyPiece,
  setSelectedHistory,
  deleteHistoryItem
}: {
  index: number,
  isLoading: boolean,
  selectedHistory: string,
  history: QuestionHistoryContainerItem[],
  historyPiece: QuestionHistoryContainerItem,
  setSelectedHistory: (historyId: string) => void,
  deleteHistoryItem: (historyId: string) => void
}) {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);

  const showDeleteButton =
    !isLoading
    && history.length != 0
    && !(history.length === 1 && history[0].history.length === 0)
    && (isHovering || historyPiece.sessionId === selectedHistory);

  return (
    <Box
      key={index}
      sx={historySelectionStyle}
      style={historyPiece.sessionId === selectedHistory ? selectedStyle : {}}
      onClick={() => setSelectedHistory(historyPiece.sessionId)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <Typography noWrap sx={{ color: 'white' }}>
        {historyPiece.history.length === 0
          ? t("history.please_ask_a_question")
          : historyPiece.history[0].message.content}
      </Typography>
      {showDeleteButton &&
        <Tooltip title="Delete session">
          <IconButton
            onClick={() => deleteHistoryItem(historyPiece.sessionId)}
            sx={{
              textTransform: 'none',
              padding: '5px',
              backgroundColor: 'none',
              ':hover': {
                bgcolor: LIGHT_GRAY_TEXT_COLOUR,
              },
            }}>
            <DeleteIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>}

    </Box>
  );
}

const selectedStyle = {
  background: LIGHT_GRAY_BORDER_COLOUR
}

const historySelectionStyle = {
  height: '60px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  paddingLeft: '20px',
  fontSize: '12pt',
  letterSpacing: '0.5px',
  cursor: 'pointer',
  borderRadius: 3
};
export default HistoryLine;
