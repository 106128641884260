import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import DisclaimerPopup from "../components/disclaimer-popup";
import { getShowPopupLocalStorage, saveShowPopupToLocalStorage } from "../helpers/local-storage-helpers";

type DisclaimerPopupProviderProps = {
  children?: any
}

interface DisclaimerPopupContextData {
  showDisclaimerPopup: boolean,
  setShowDisclaimerPopup: Dispatch<SetStateAction<boolean>>
}

const DisclaimerPopupContext = createContext<DisclaimerPopupContextData>({
  showDisclaimerPopup: false,
  setShowDisclaimerPopup: () => { }
});

export const DisclaimerPopupProvider: React.FC<DisclaimerPopupProviderProps> = ({ children }: { children?: any }) => {
  const [showDisclaimerPopup, setShowDisclaimerPopup] = useState<boolean>(false);

  useEffect(() => {
    const shouldShowDisclaimerPopup = getShowPopupLocalStorage();
    if (shouldShowDisclaimerPopup < 2) {
      setShowDisclaimerPopup(true);
    }
  }, [])

  const handleAcceptDisclaimer = () => {
    setShowDisclaimerPopup(false);
    saveShowPopupToLocalStorage()
  };

  return (
    <DisclaimerPopupContext.Provider
      value={{
        showDisclaimerPopup,
        setShowDisclaimerPopup
      }}
    >
      {showDisclaimerPopup &&
        <DisclaimerPopup handleAccept={handleAcceptDisclaimer} />}
      {children}
    </DisclaimerPopupContext.Provider>
  )
}

export function useDisclaimerPopupProvider() {
  return useContext(DisclaimerPopupContext);
}