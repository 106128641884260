import { Box, Button, Typography } from "@mui/material";
import { BASE_BACKGROUND_COLOUR, CHAT_MESSAGE_COLOUR, LIGHT_GRAY_BORDER_COLOUR, LIGHT_GRAY_TEXT_COLOUR } from "../constants/colours";
import { useTranslation } from "react-i18next";
import ErrorIcon from '@mui/icons-material/Error';

const DisclaimerPopup = ({
  handleAccept
}: {
  handleAccept: () => void
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={overlayContainerStyle}>
      <Box sx={overlayStyle} />
      <Box sx={popupContentStyle}>
        <ErrorIcon sx={warningIconStyle} />
        <Typography sx={disclaimerStyle}>{t('disclaimer.heading')}</Typography>
        <Typography sx={disclaimerTextStyle}>{t('disclaimer.disclaimerText')}</Typography>
        <Box sx={popupActionsStyle}>
          <Box sx={buttonStyle} onClick={handleAccept}>{t('disclaimer.ok')}</Box>
        </Box>
      </Box>
    </Box>
  );
}

const overlayContainerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white'
}

const overlayStyle = {
  position: 'absolute',
  zIndex: 9,
  backgroundColor: 'black',
  width: '100%',
  height: '100%',
  opacity: 0.6
}

const popupContentStyle = {
  position: 'absolute',
  backgroundColor: BASE_BACKGROUND_COLOUR,
  borderRadius: '8px',
  zIndex: 10,
  width: { xs: '85vw', sm: '70vw', md: '40vw', lg: '35vw', xl: '30vw' },
  height: 'auto',
  padding: '30px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center'
}

const warningIconStyle = {
  width: '70px',
  height: '70px',
  color: LIGHT_GRAY_TEXT_COLOUR
}

const disclaimerStyle = {
  letterSpacing: '1px',
  marginTop: '10px',
  fontSize: '20px',
  fontWeight: 'bold'
}

const disclaimerTextStyle = {
  letterSpacing: '0.5px',
  marginTop: '20px',
  fontSize: '17px',
}

const popupActionsStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
}

const buttonStyle = {
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: '18px',
  border: '1px solid white',
  borderRadius: '8px',
  padding: '5px 30px 5px 30px',
  width: 'auto',
  transition: '0.3s',
  ':hover': {
    bgcolor: 'white',
    fontSize: '18px',
    color: CHAT_MESSAGE_COLOUR,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOUR}`,
  },
}

export default DisclaimerPopup;
