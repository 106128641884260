import React, { useState } from "react";
import './side-bar.css';
import { Box, Typography } from "@mui/material";
import { DARK_GRAY_BUTTON_COLOUR } from "../constants/colours";

const Sidebar = ({ content }: { content: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
  }

  return (
    <Box className={`sidebar ${isOpen == true ? 'active' : ''}`} sx={sidebarStyle}>
      <Box onClick={ToggleSidebar} sx={buttonStyle}>
        <Typography sx={timerStyle}>
          ⏳
        </Typography>
      </Box>
      {content}
    </Box>
  )
}

const buttonStyle = {
  backgroundColor: `${DARK_GRAY_BUTTON_COLOUR} !important`,
  color: '#fff',
  position: 'absolute',
  right: '-40px',
  top: '30px',
  display: { md: 'block', lg: 'none' },
  height: '40px',
  width: '50px',
  borderRadius: '20%',
}

const timerStyle = {
  marginRight: '-10px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const sidebarStyle = {
  left: { md: '-300px !important', lg: '0px !important' },
}

export default Sidebar;