import ChatWindow from './components/chat-window';
import HistoryContainer from './components/history-container';
import { useEffect, useState } from 'react';
import { Message, QNA, QuestionHistory, ResponseSource } from './constants/types';
import Sidebar from './components/side-bar';
import { Box } from '@mui/material';
import ViewSourcesContent from './components/view-sources-content';
import LanguageSelector from './components/language-selector';
import { getHistoryFromLocalStorage, getShowPopupLocalStorage, saveShowPopupToLocalStorage } from './helpers/local-storage-helpers';
import { generateId } from './helpers/generate-id';
import DisclaimerPopup from './components/disclaimer-popup';
import { useHistoryProvider } from './providers/history-provider';
import { useSourcesPanelProvider } from './providers/sources-panel-provider';
import { useDisclaimerPopupProvider } from './providers/disclaimer-popup-provider';
import { useChatWindowProvider } from './providers/chat-window-provider';

function HomeScreen() {
  const {
    history,
    setHistory,
    selectedHistory,
    setSelectedHistory,
    createNewSession,
    setCurrentLoadingHistory,
    canCreateNewSession
  } = useHistoryProvider();
  const { input, setInput, isLoading, setIsLoading } = useChatWindowProvider();
  const { setIsViewSourcesPanelOpen } = useSourcesPanelProvider();

  useEffect(() => {
    setIsViewSourcesPanelOpen(false);
  }, [selectedHistory]);

  const handleSetSessionHistory = (newSessionHistory: QuestionHistory, sessionId: string) => {
    let currentSessionHistory = history.find((historyPiece) => historyPiece.sessionId === sessionId);
    let updatedhistory = [...history];

    if (currentSessionHistory && currentSessionHistory?.history.length == 2) {
      const newSessionId = generateId();
      const newHistoryItem = { sessionId: newSessionId, history: [newSessionHistory] };
      updatedhistory[0] = newHistoryItem;
    } else if (currentSessionHistory && currentSessionHistory?.history.length < 2) {
      currentSessionHistory.history.push(newSessionHistory);
      var foundIndex = history.findIndex((historyPiece) => historyPiece.sessionId === sessionId);
      updatedhistory[foundIndex] = currentSessionHistory;
    }

    setHistory(updatedhistory);
  }

  const deleteHistoryItem = (historyId: string) => {
    let newHistory = history.filter((historyItem) => historyItem.sessionId !== historyId);
    setHistory(newHistory);
    setInput('');
    if (newHistory.length === 0) {
      const newId = generateId();
      setHistory([{ sessionId: newId, history: [] }]);
    }
  }

  const handleSubmit = async (selectedHistoryId: string) => {
    setIsLoading(true);
    setCurrentLoadingHistory(selectedHistoryId);
    const prompt: Message = {
      role: 'user',
      content: input
    }
    handleSetSessionHistory({
      message: prompt,
      qna: [],
      responseSources: []
    }, selectedHistoryId);

    setInput("");

    try {
      const currentLanguageCode = localStorage.getItem('i18nextLng') || 'en';
      const [baseData, qna] = await Promise.all([
        fetch("https://jy72awmm53xxe62rkyghhst6fa0krvru.lambda-url.us-west-2.on.aws/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: input,
            language_code: currentLanguageCode
          })
        }).then((res) => res.json()),
        fetch("https://pajjkmrzlvyu7pipuokhcre3vy0vzyqv.lambda-url.us-west-2.on.aws/ ", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: input,
            language_code: currentLanguageCode
          })
        }).then((res) => res.json()),
      ]);

      const res1 = baseData.response;
      const sources1 = baseData.source.map((source: any) => {
        return {
          title: source.kwargs.metadata.title,
          summary: source.kwargs.page_content,
          sourceLink: source.kwargs.metadata.link
        } as ResponseSource
      });
      const newMessage: Message = {
        role: "chatbot",
        content: res1,
      }

      const qnas2: QNA[] = qna.response;

      handleSetSessionHistory({
        message: newMessage,
        qna: qnas2,
        responseSources: sources1
      }, selectedHistoryId);

    } catch (error) {
      console.log(`Error: ${error}`);
      const newMessage: Message = {
        role: "chatbot",
        content: "I cannot answer your question right now. Something is wrong with my backend.",
      }
      handleSetSessionHistory({
        message: newMessage,
        qna: [],
        responseSources: []
      }, selectedHistoryId);
    } finally {
      setIsLoading(false);
    }
  }

  const handleKeyPress = (event: { keyCode: number; which: number; }) => {
    if ((event.keyCode === 13 || event.which === 13) && (!isLoading && input !== "")) {
      handleSubmit(selectedHistory);
    }
  };

  return (
    <Box sx={homeScreenStyle}>
      <LanguageSelector />
      <Sidebar content={
        <HistoryContainer
          isLoading={isLoading}
          selectedHistory={selectedHistory}
          history={history}
          setSelectedHistory={setSelectedHistory}
          createNewSession={createNewSession}
          deleteHistoryItem={deleteHistoryItem}
          canCreateNewSession={canCreateNewSession} />
      } />
      <Box sx={appContainerRightStyle}>
        <Box sx={chatWindowContainerStyle}>
          <ChatWindow
            handleSubmit={!isLoading && input !== "" ? () => handleSubmit(selectedHistory) : () => { }}
            handleKeyPress={handleKeyPress}
            createNewSession={createNewSession} />
        </Box>
      </Box>
    </Box>
  );
}

const chatWindowContainerStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  width: { lg: '45%', md: '80%', sm: '80%', xs: '80%' }
}

const homeScreenStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(10px +  2vmin)',
  color: 'white',
};

const appContainerRightStyle = {
  width: '100%',
};


export default HomeScreen;
