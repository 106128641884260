import React, { useState } from "react";
import './view-sources-panel.css';
import { Box, Typography } from "@mui/material";
import { DARK_GRAY_BUTTON_COLOUR } from "../constants/colours";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const ViewSourcesPanel = ({ content, isOpen, closePanel }: { content: any, isOpen: boolean, closePanel: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box className={`viewSourcesPanel ${isOpen == true ? 'active' : ''}`} sx={viewSourcesPanelStyle}>
        <Box sx={headerBoxStyle}>
          <Typography sx={headerStyle}>
            {t("view_sources.sources")}
          </Typography>
          <Box onClick={closePanel}>
            <CloseIcon sx={closeIconStyle} />
          </Box>
        </Box>

        {content}
      </Box>
    </>
  )
}

const viewSourcesPanelStyle = {
  backgroundColor: DARK_GRAY_BUTTON_COLOUR
}

const headerStyle = {
  textAlign: 'left',
  fontSize: 23,
  color: 'white'
}

const headerBoxStyle = {
  paddingLeft: '32px',
  paddingRight: '32px',
  paddingTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const closeIconStyle = {
  color: 'white',
  cursor: 'pointer'
}

export default ViewSourcesPanel;