import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { QuestionHistory } from "../constants/types";

type ChatWindowProviderProps = {
  children?: any
}

interface ChatWindowContextData {
  input: string,
  setInput: Dispatch<SetStateAction<string>>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  currentHistory: QuestionHistory[],
  setCurrentHistory: Dispatch<SetStateAction<QuestionHistory[]>>,
  isQuestionBoxDisabled: boolean,
  setIsQuestionBoxDisabled: Dispatch<SetStateAction<boolean>>,
}

const ChatWindowContext = createContext<ChatWindowContextData>({
  input: '',
  setInput: () => { },
  isLoading: false,
  setIsLoading: () => { },
  currentHistory: [],
  setCurrentHistory: () => { },
  isQuestionBoxDisabled: false,
  setIsQuestionBoxDisabled: () => { }
});

export const ChatWindowProvider: React.FC<ChatWindowProviderProps> = ({ children }: { children?: any }) => {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentHistory, setCurrentHistory] = useState<QuestionHistory[]>([]);
  const [isQuestionBoxDisabled, setIsQuestionBoxDisabled] = useState<boolean>(currentHistory.length > 0);

  useEffect(() => {
    setIsQuestionBoxDisabled(currentHistory.length > 0);
  }, [currentHistory])

  return (
    <ChatWindowContext.Provider
      value={{
        input,
        setInput,
        isLoading,
        setIsLoading,
        currentHistory,
        setCurrentHistory,
        isQuestionBoxDisabled,
        setIsQuestionBoxDisabled
      }}
    >
      {children}
    </ChatWindowContext.Provider>
  )
}

export function useChatWindowProvider() {
  return useContext(ChatWindowContext);
}