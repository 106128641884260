import './chat-message.css';
import SimilarQuestions from "./similar-questions";
import { Message, QNA, ResponseSource } from "../constants/types";
import { Box, Button, Typography } from "@mui/material";
import { CHAT_MESSAGE_COLOUR, LIGHT_GRAY_BORDER_COLOUR } from "../constants/colours";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Parser } from 'html-to-react';

const ChatMessage = ({
  message,
  qna,
  responseSource,
  toggleViewSourcesPanel
}: {
  message: Message,
  qna: QNA[],
  responseSource: ResponseSource[],
  toggleViewSourcesPanel: (responseSources: ResponseSource[]) => void
}) => {
  const { t } = useTranslation();

  // const [displayResponse, setDisplayResponse] = useState<string>('');

  // useEffect(() => {
  //   let i = 0;
  //   const stringResponse = message.content

  //   const intervalId = setInterval(() => {
  //     setDisplayResponse(stringResponse.slice(0, i));

  //     i++;

  //     if (i > stringResponse.length) {
  //       clearInterval(intervalId);
  //     }
  //   }, 1);

  //   return () => clearInterval(intervalId);
  // }, [message]);

  return (
    <Box sx={chatMessageWindowStyle}>
      <Box sx={chatMessageContentStyle}>
        {message.role === "chatbot" ?
          <>
            <Box
              style={{
                fontSize: '13pt',
                letterSpacing: '0.5px',
                lineHeight: '20pt',
              }}>
              {Parser().parse(message.content)}
            </Box>

            {responseSource.length > 0 &&
              <Box sx={viewSourcesStyle}>
                <Button
                  onClick={() => toggleViewSourcesPanel(responseSource)}
                  disableRipple
                  sx={viewSourcesButtonStyle}>
                  {t("chat_message.view_sources")}
                </Button>
              </Box>}


            {qna.length > 0 && <SimilarQuestions data={qna} />}
          </>
          :
          <Typography sx={userInputStyle}>{message.content}</Typography>
        }
      </Box>
    </Box>
  );
}

const userInputStyle = {
  fontSize: '30px'
}

const chatMessageWindowStyle = {
  position: 'relative',
  minHeight: '60px',
  marginBottom: '20px',
  borderRadius: '10px',
}

const chatMessageContentStyle = {
  textAlign: 'left',
  fontSize: '12pt',
  letterSpacing: '0.5px',
  lineHeight: '20pt',
}

const viewSourcesStyle = {
  marginTop: '20px',
}

const viewSourcesButtonStyle = {
  textTransform: 'none',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '5px',
  paddingBottom: '5px',
  backgroundColor: LIGHT_GRAY_BORDER_COLOUR,
  color: 'white',
  ':hover': {
    bgcolor: CHAT_MESSAGE_COLOUR,
    color: 'white',
  },
}

export default ChatMessage;