import { Box, FormControl, InputLabel, MenuItem, Select, ThemeProvider, createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect, useState } from "react";


const LanguageSelector = () => {
  const { t } = useTranslation();
  const [languageCode, setLanguageCode] = useState<string>('en');
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  useEffect(() => {
    const currentLanguageCode = localStorage.getItem('chosenLanguage') || 'en';
    i18next.changeLanguage(currentLanguageCode)
    setLanguageCode(currentLanguageCode)
  }, [])

  return (
    <Box sx={languageSelectorStyle}>
      <ThemeProvider theme={darkTheme}>
        <FormControl fullWidth >
          <InputLabel>{t("language_selector.language")}</InputLabel>
          <Select
            value={languageCode}
            label={t("language_selector.language")}
            onChange={(event) => {
              setLanguageCode(event.target.value)
              i18next.changeLanguage(event.target.value)
              localStorage.setItem('chosenLanguage', event.target.value)
            }}
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'zh_TW'}>{"中文 (繁體)"}</MenuItem>
            <MenuItem value={'zh_CN'}>{"中文 (简体)"}</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    </Box>
  )
}

const languageSelectorStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  borderRadius: '5px',
};

export default LanguageSelector;