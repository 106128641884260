import './view-sources-content.css';
import { Box, Card, CardContent, Typography } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import { DARK_GRAY_BUTTON_COLOUR, LIGHT_GRAY_BORDER_COLOUR, LINK_COLOUR } from "../constants/colours";
import { ResponseSource } from "../constants/types";
import { openInNewTab } from '../helpers/open-tab-in-new-link';

const ViewSourcesContent = ({ responseSources, selectedHistory }: { responseSources: ResponseSource[], selectedHistory: string }) => {
  return (
    <div className={'responseSourceContent'} style={responseSourceContentStyle}>
      {responseSources.map((responseSource, index) =>
        <Card key={selectedHistory + `-${index}`} sx={responseSourceCardStyle}>
          <CardContent>
            <Box sx={headerBoxStyle} onClick={() => openInNewTab(responseSource.sourceLink)}>
              <Typography noWrap gutterBottom component="div" sx={headerStyle}>
                {responseSource.title}
              </Typography>
              <LaunchIcon sx={{ height: 23, width: 23, color: LINK_COLOUR }} />
            </Box>

            <Typography variant="body1" sx={summaryStyle}>
              {responseSource.summary}
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

const responseSourceContentStyle = {
  padding: '15px',
  height: '100vh',
  overflow: "auto",
}

const responseSourceCardStyle = {
  border: `2px solid ${LIGHT_GRAY_BORDER_COLOUR}`,
  background: DARK_GRAY_BUTTON_COLOUR,
  marginBottom: '15px',
  textAlign: 'left'
}

const headerBoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
}

const headerStyle = {
  color: LINK_COLOUR,
  fontSize: 19
}

const summaryStyle = {
  color: 'white',
  fontSize: 14
}

const buttonStyle = {
  textTransform: 'none',
  padding: '5px 15px 5px 15px',
  backgroundColor: LIGHT_GRAY_BORDER_COLOUR,
  color: 'white',
  ':hover': {
    bgcolor: 'white',
    color: DARK_GRAY_BUTTON_COLOUR,
  },
}

export default ViewSourcesContent;