import i18next from "i18next"
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import HTTPApi from "i18next-http-backend"

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HTTPApi)
    .init({
        detection: options,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        supportedLngs: ['en', 'zh_TW', 'zh_CN']
    })

export default i18next