import './history-container.css'
import { DARK_GRAY_BUTTON_COLOUR, LIGHT_GRAY_BORDER_COLOUR, LIGHT_GRAY_TEXT_COLOUR } from '../constants/colours';
import { QuestionHistoryContainerItem } from '../constants/types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import HistoryLine from './history-line';

function HistoryContainer({
  isLoading,
  selectedHistory,
  history,
  setSelectedHistory,
  createNewSession,
  deleteHistoryItem,
  canCreateNewSession
}: {
  isLoading: boolean,
  selectedHistory: string,
  history: QuestionHistoryContainerItem[],
  setSelectedHistory: (historyId: string) => void,
  createNewSession: () => void,
  deleteHistoryItem: (historyId: string) => void,
  canCreateNewSession: () => boolean
}) {
  const { t } = useTranslation();
  return (
    <Box sx={historyContainerStyle}>
      <Box sx={historyHeaderStyle}>
        ⏳ {t("history.header")}
        {(canCreateNewSession() && !isLoading) && <Box className="newSession">
          <Tooltip title="Create new session">
            <IconButton
              onClick={createNewSession}
              sx={{
                textTransform: 'none',
                padding: 0,
                backgroundColor: 'none',
                ':hover': {
                  bgcolor: LIGHT_GRAY_TEXT_COLOUR,
                },
              }}>
              <AddIcon style={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Box>}
      </Box>
      <div className={'historyListStyleCss'} style={historyListStyle}>
        {history.length > 0 && history.map((historyPiece, index) =>
          <HistoryLine
            key={index}
            index={index}
            isLoading={isLoading}
            selectedHistory={selectedHistory}
            history={history}
            historyPiece={historyPiece}
            setSelectedHistory={setSelectedHistory}
            deleteHistoryItem={deleteHistoryItem} />
        )}
      </div>
    </Box>
  );
}

const selectedStyle = {
  background: LIGHT_GRAY_BORDER_COLOUR
}

const historyContainerStyle = {
  width: '300px',
  height: '100vh',
  background: DARK_GRAY_BUTTON_COLOUR,
};

const historyHeaderStyle = {
  height: '120px',
  fontSize: '13pt',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  padding: '20px',
  paddingLeft: '30px',
  color: 'white',
};

const historySelectionStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  paddingLeft: '20px',
  fontSize: '12pt',
  letterSpacing: '0.5px',
  cursor: 'pointer',
  borderRadius: 3
};

const historyListStyle = {
  overflow: 'auto',
  height: '80%',
  paddingLeft: '10px',
  paddingRight: '10px'
}

export default HistoryContainer;
