import { QuestionHistoryContainerItem } from "../constants/types";
import { generateId } from "./generate-id";

const saveToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
}

const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
}

export const saveHistoryToLocalStorage = (history: QuestionHistoryContainerItem[]) => {
  saveToLocalStorage('history', JSON.stringify(history))
}

export const getHistoryFromLocalStorage = () => {
  const newSessionId = generateId();
  const fromLocalStorage = getFromLocalStorage('history');
  return JSON.parse(!fromLocalStorage || fromLocalStorage == '[]' ? `[{ "sessionId": "${newSessionId}", "history": [] }]` : fromLocalStorage) as QuestionHistoryContainerItem[];
}

export const saveShowPopupToLocalStorage = () => {
  let timesShown = JSON.parse(getFromLocalStorage('showDisclaimer') ?? `0`);
  if (timesShown < 2) {
    timesShown += 1;
  }
  saveToLocalStorage('showDisclaimer', JSON.stringify(timesShown))
}

export const getShowPopupLocalStorage = () => {
  return JSON.parse(getFromLocalStorage('showDisclaimer') ?? `0`);
}