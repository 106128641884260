import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LIGHT_GRAY_BORDER_COLOUR, BASE_BACKGROUND_COLOUR, LINK_COLOUR } from "../constants/colours";
import { QNA } from "../constants/types";
import { useTranslation } from "react-i18next";
import LaunchIcon from '@mui/icons-material/Launch';
import { openInNewTab } from "../helpers/open-tab-in-new-link";

const SimilarQuestions = ({ data }: { data: QNA[] }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      defaultExpanded={true}
      sx={accordionStyle}>
      <AccordionSummary
        style={accordionSummaryStyle}
        expandIcon={<ExpandMoreIcon style={expandMoreIconStyle} />}>
        {t("similar_questions.similar_questions")}
      </AccordionSummary>
      <AccordionDetails
        style={accordionSummaryStyle}>
        {data && data.map(
          (qna, index) => <QuestionAndAnswer key={index} question={qna.question} answer={qna.answer} link={qna.link} />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

const QuestionAndAnswer = ({ question, answer, link }: { question: string, answer: string, link: string }) => {
  const { t } = useTranslation();

  return (
    <Accordion style={innerAccordionStyle}>
      <AccordionSummary
        style={innerAccordionSummaryStyle}
        expandIcon={<ExpandMoreIcon style={expandMoreIconStyle} />}>
        {question}
      </AccordionSummary>
      <AccordionDetails
        style={innerAccordionSummaryStyle}>
        {answer}
        <Box sx={sourceLinkBoxStyle} onClick={() => openInNewTab(link)}>
          {t("view_sources.sources")}
          <LaunchIcon sx={sourceLinkIconStyle} />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const accordionStyle = {
  background: BASE_BACKGROUND_COLOUR,
  color: 'white',
  marginTop: 0,
  borderColor: LIGHT_GRAY_BORDER_COLOUR,
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  }
}

const accordionSummaryStyle = {
  padding: 0
}

const innerAccordionSummaryStyle = {
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0
}

const innerAccordionStyle = {
  background: BASE_BACKGROUND_COLOUR,
  color: 'white',
  borderColor: LIGHT_GRAY_BORDER_COLOUR,
  borderStyle: 'solid',
  borderWidth: '1px 0 0px 0',
  borderRadius: 0,
  boxShadow: 'none',
  marginTop: 0,
  marginBottom: 0,
}

const expandMoreIconStyle = {
  color: 'white'
}

const sourceLinkBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  color: LINK_COLOUR,
  cursor: 'pointer'
}

const sourceLinkIconStyle = {
  height: 20,
  width: 20,
  color: LINK_COLOUR,
  marginLeft: '3px'
}

export default SimilarQuestions;