import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from './home-screen';
import { HistoryProvider } from './providers/history-provider';
import { SourcesPanelProvider } from './providers/sources-panel-provider';
import { DisclaimerPopupProvider } from './providers/disclaimer-popup-provider';
import { ChatWindowProvider } from './providers/chat-window-provider';
import HomeScreenCustomerService from './home-screen-customer-service';

function App() {
  return (
    <div className="App">
      <HistoryProvider>
        <SourcesPanelProvider>
          <DisclaimerPopupProvider>
            <ChatWindowProvider>
              <BrowserRouter>
                <div className="container">
                  <Routes>
                    <Route path="/customer_service" element={<HomeScreenCustomerService />} />
                    <Route path="/" element={<HomeScreen />} />
                  </Routes>
                </div>
              </BrowserRouter>
            </ChatWindowProvider>
          </DisclaimerPopupProvider>
        </SourcesPanelProvider>
      </HistoryProvider>
    </div>
  );
}

export default App;
