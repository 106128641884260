import { useState } from "react";
import { BASE_BACKGROUND_COLOUR, DARK_GRAY_BUTTON_COLOUR, LIGHT_GRAY_BORDER_COLOUR } from "../constants/colours";
import SendIcon from "../images/send-icon";
import "./question-input-box.css";
import { useTranslation } from "react-i18next";

const QuestionInputBox = ({
  value,
  isLoading,
  onChange,
  onClick
}: {
  value: string,
  isLoading: boolean,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void,
  onClick: () => void
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const defaultColour = value === '' || isLoading ? LIGHT_GRAY_BORDER_COLOUR : 'white';
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div className="wrapper" style={{ border: `1px solid ${LIGHT_GRAY_BORDER_COLOUR}` }}>
      <input
        className="input"
        placeholder={t("question_input_box.what_would_you_like_to_ask_me")}
        value={value}
        onChange={onChange}
      />
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="btn"
        onClick={onClick}
        style={{ backgroundColor: `${isHover ? DARK_GRAY_BUTTON_COLOUR : defaultColour}` }}>
        <SendIcon size={25} color={BASE_BACKGROUND_COLOUR} />
      </div>
    </div>
  )
}

export default QuestionInputBox;