import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import ViewSourcesContent from "../components/view-sources-content";
import ViewSourcesPanel from "../components/view-sources-panel";

type SourcesPanelProviderProps = {
  children?: any
}

interface SourcesPanelContextData {
  viewSourcesPanelContent: React.ReactNode,
  setIsViewSourcesPanelOpen: Dispatch<SetStateAction<boolean>>
  setViewSourcesPanelContent: Dispatch<React.ReactNode>
  handleToggleViewSourcesPanel: () => void
}

const SourcesPanelContext = createContext<SourcesPanelContextData>({
  viewSourcesPanelContent: <></>,
  setIsViewSourcesPanelOpen: () => { },
  setViewSourcesPanelContent: () => { },
  handleToggleViewSourcesPanel: () => { }
});

export const SourcesPanelProvider: React.FC<SourcesPanelProviderProps> = ({ children }: { children?: any }) => {
  const [isViewSourcesPanelOpen, setIsViewSourcesPanelOpen] = useState<boolean>(false);
  const [viewSourcesPanelContent, setViewSourcesPanelContent] = useState<React.ReactNode>(<ViewSourcesContent responseSources={[]} selectedHistory={''} />);

  const handleToggleViewSourcesPanel = () => {
    setIsViewSourcesPanelOpen(!isViewSourcesPanelOpen);
  }

  return (
    <SourcesPanelContext.Provider
      value={{
        viewSourcesPanelContent,
        setIsViewSourcesPanelOpen,
        setViewSourcesPanelContent,
        handleToggleViewSourcesPanel
      }}
    >
      {children}
      <ViewSourcesPanel content={viewSourcesPanelContent} isOpen={isViewSourcesPanelOpen} closePanel={() => setIsViewSourcesPanelOpen(false)} />
    </SourcesPanelContext.Provider>
  )
}

export function useSourcesPanelProvider() {
  return useContext(SourcesPanelContext);
}